:root {
  --brand-color: #5c46b3;
  --font-family: 'DM Sans', sans-serif;
}

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  padding-left: 16px;
}

.dropdown {
  margin-bottom: 15px;
}

label {
  margin-right: 10px;
}

select {
  padding: 4px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 100px;
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: 700;
  width: 100px;
  border: none;
  border: 1px solid #ccc;
  
}
.landSelect{
  width:130px;
}

.genderSelect{
  margin-left: 8px;
  width:110px;
}

.customDropdown {
  width: 200px;
  margin-left: 16px;
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 12px
}

.optionsContainer {
  position: relative;
  width: 180px;
  display: inline-block;
  border: 1px solid #ccc;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 6px;
  padding-right:6px;
  border-radius: 100px;
}

.selectedOption {
  padding: 10px;
  cursor: pointer;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.selectedOption:hover {
  background-color: #f9f9f9;
}

.dropdownIcon {
  margin-left: 5px;
}

.dropdownContent {
  position: absolute;
  overflow-y: scroll;
  max-height: 300px;
  margin-top: 10px;
  top: 100%;
  left: 0;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
  width: 100%;
}
.playBtn{
  display: grid;
  place-content: center;
  color:#333;
  padding: 4px;
  border-radius: 100px;
  margin-left: 14px;
  background-color: #ccc;
}

.playBtn:hover{
  color:white;
  background-color: var(--brand-color);
}

.option {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.option:last-child {
  border-bottom: none;
}

.option:hover {
  background-color: #f9f9f9;
}

.selected {
  background-color: #3498db;
  color: #fff;
}

button {
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 6px 10px;
  margin-left: 10px;
}

button:hover {
  background-color: #2980b9;
}

.langdropdown{
  position: relative;
  font-family: var(--font-family);
}
