#audiences-container{
    padding: 8px;
    margin-top: 20px;;
    border: 1px solid #f4f4f4;
    border-radius: 10px;
}

.audience-item{
    display: flex;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    margin-bottom:8px;
    padding: 8px;
    border-radius: 10px;
}

.audience-radio{
    width:18px;
    margin-right:16px;
}

.audience-item:hover{
    background-color: #f7f5fd;
}