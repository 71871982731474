/* ChatPane.module.css */

/* Define a CSS variable for the brand color */
:root {
  --brand-color: #5c46b3;
  --font-family: 'DM Sans', sans-serif;
}



::-webkit-scrollbar {
  width: 8px;
  height: 0px;
  /* Set the width of the scrollbar */
}

::-webkit-scrollbar-thumb {
  background-color: var(--brand-color);
  /* Set the color of the scrollbar thumb */
  border-radius: 4px;
  /* Optional: Add rounded corners to the scrollbar thumb */
}



.chatPane {
  position: fixed;
  bottom: 20px;
  left: 20px;
  width: 400px;
  min-height: 400px;
  max-height: 800px;
  border-radius: 12px;
  border: 1px solid #ccc;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  display: flex;
  background-color: white;
  z-index: 2;
  flex-direction: column;
}

.FREcontainer {
  height: 100%;
  padding: 8px;
  text-align: center;
  font-family: var(--font-family);

}

.logoContainer {
  display: grid;
  place-content: center;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 1);
  height: 46px;
  width: 46px;
  margin-top: 10px;
  border-radius: 100px;
}

.smallControl{
  border: 3px solid var(--brand-color);
  position: fixed;
  bottom: 30px;
  left: 30px;
  height: 50px;
  overflow: hidden;
  width: 50px;
  display: grid;
  place-content: center;
  border-radius: 100px;
}

.logoCollapsed{
  width: 100%;
 
  
}

.logo {
  width: 100%;
}

.header {
  background-color: var(--brand-color);
  position: relative;
  display: grid;
  place-content: center;
  grid-template-columns: 60px 1fr 20px;
  color: #fff;
  height: 48px;
  padding: 10px;
  backdrop-filter: blur(20px);
  opacity: 1;
  background: linear-gradient(rgb(90, 51, 242), rgba(95, 171, 229, 0.7));
  font-family: var(--font-family);
}

.messages {
  flex: 1;
  padding: 16px;
  overflow-y: scroll;
  scroll-behavior: smooth;
  font-family: var(--font-family);
}

.msgContainer {
  position: relative;
}

.audioIcon {
  position: absolute;
  background-color: var(--brand-color);
  display: grid;
  right: -10px;
  bottom: -22px;
  place-content: center;
  width: 30px;
  height: 30px;
  border: 4px solid white;
  border-radius: 100px;
}

.sendBtn {
  display: grid;
  place-content: center;
  background-color: var(--brand-color);
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  font-family: var(--font-family);
}

.userMessage {
  position: relative;
  background-color: var(--brand-color);
  color: #fff;
  border-radius: 5px;
  padding: 12px 16px;
  width: fit-content;
  margin-right: auto;
  margin-bottom: 20px;
  font-family: var(--font-family);
  animation-name: slideIn;
  animation-duration: .33s;
  animation-timing-function: cubic-bezier(0.33, 0, 0.165, 1);
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(100px) scale(.5);
  }

  100% {
    opacity: 1;
    transform: translateY(0px) scale(1);
  }
}

.botMessage {
  position: relative;
  background-color: #efecf1;
  width: fit-content;
  margin-left: auto;
  border-radius: 8px;
  padding: 12px 16px;
  margin-bottom: 20px;
  font-family: var(--font-family);
  white-space: pre-line; 
}

.inputContainer {
  display: grid;
  grid-template-columns: 1fr 40px;
  padding: 10px;
  align-items: center;
}

input {
  flex: 1;  /* Use flex: 1 to allow the input to grow and take up available space */
  padding: 8px;
  border: 1px solid #ccc;
  height: 16px;
  padding-right: 30px;
  border-radius: 5px;
  outline: none;
  font-family: var(--font-family);
}

input:focus {
  outline: 1px solid var(--brand-color);
  border: none
}

.innerInput {
  position: relative;
  display: flex;  /* Use flex to control the layout */
  width: 100%;
}

.micContainer {
  display: grid;
height: 100%;
  width: 16px;
  position: absolute;

  border-radius: 100px;
  top: 0px;
  right: 6px;
  opacity: .8;
  place-content: center;
}

.micContainer:hover{
  opacity: 1;
}
.unfilledMic{
    opacity:.75;
  
}

.filledMic{
  animation-name: pulse;
  animation-duration: 1.2s;
  animation-iteration-count: infinite;
}
.unfilledMic:hover{
  color:var(--brand-color);
  opacity:1;
}

.listeningTxt{
  background-color: var(--brand-color);
  color:white;
  font-family: var(--font-family);
  text-align: center;
  font-size:12px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 8px 8px 0px 0px;

}

@keyframes pulse {
  0%{
    opacity: 1;
  }
  50%{
    opacity: .7;
  }
  100%{
    opacity: 1;
  }
}
