.faq-container {
    width: 100%;
    font-family: DM Sans;
    max-width: 700px;
    margin: 0 auto;
  }
  
  .faq {
    border: 1px solid rgb(231, 231, 231);
    border-radius: 10px;
    padding-right:15px;
    padding-left:15px;
    margin-bottom: 20px;
  }
  
  .faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    cursor: pointer;
  }
  
  .faq-question h3 {
    margin: 0;
  }
  
  .faq-icon {
    font-size: 20px;
    font-weight: bold;
    transform: rotate(0deg);
    transition: all 0.3s ease;
  }
  
  .faq-icon.active {
    transform: rotate(45deg);
  }
  
  .faq-answer {

    padding: 10px;
    transition: all .33s cubic-bezier(.33,0,0,1)
  }
  