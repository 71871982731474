.ad-campaign {
    font-family: DM Sans;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }

  .section-container{
    border: 2px solid lightgrey;
    border-radius: 12px;
    margin: 16px;
    padding: 16px;
  }

  .data-group{
   margin-bottom: 8px;
   margin-top:8px
  }

  .pw-ptrotected-wrapper{
    display: grid;
    place-content: center;
    margin-top:-40px;
    height:100vh;
    width:100vw;
    background-color: #f4f4f4;
  }

  .enter-pwd-label{
    color:var(--primary-color);
    text-align: center;
    font-family: DM Sans;
    font-weight: 600;
    opacity: .7;
    margin-bottom: 6px;
    margin-top: 20px;
  }

.view-audience-pill{
  padding-left:10px;
  padding-right: 10px;
  width: fit-content;
  background-color: var(--primary-color);
  border-radius: 10px;
  padding-top:4px;
  padding-bottom:4px;
  color:white;
  margin-bottom:8px;
}
  .view-label-heading{
    font-size:14px;
    font-weight: 700;
    opacity:.8
  }

  .view-label-information{
    font-size:20px;
    font-weight: 400;
  }
  
  h2, h3 {
    margin-top: 20px;
  }

  .view-h1{
    font-size: 20px;
    font-weight: 800;
  }
  
  