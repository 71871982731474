/* ChatComponent.module.css */
:root {
    --brand-color: #5c46b3;
    --font-family: 'DM Sans', sans-serif;
  }
  
  .chatComponent {
    display: block;
    position: relative;
    width: 100%;
    overflow: scroll;
    height: auto;
    border-radius: 18px;
    flex-direction: column;
    margin: 0px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
  
  .header {
    display: flex;
    padding-left: 16px;
    padding-right: 16px;
    align-items: center;
    margin-bottom: 0px;
  }
  audio{
    display: block;
    position: relative;
    width: 90%;
    margin: auto;
  }
  
  .backIcon {
    margin-right: 16px;
    cursor: pointer;
  }
  .genBtnContainer{
    display: grid;
    place-content: center;
    grid-template-columns: 1fr 50px;
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 16px;
  }
 
  .generateAudioBtn{
    display: flex;
    margin: auto;
    cursor: pointer;
    gap:10px;;
    color:white;
    background-color: var(--brand-color);
    width: 100%;
    padding-left: 20px;
    font-family: var(--font-family);
    font-weight: 700;
    padding-right: 20px;
    padding-top: 14px;
    padding-bottom: 14px;
    border-radius: 12px;
  }

  .settingsBtn{
    display: grid;
    place-content: center;
    background-color:white;
    width: 100%;
    border-radius: 10px;
    border: 2px solid var(--brand-color);
    color:var(--brand-color);
  }
  .settingsBtnGen{
    display: grid;
    place-content: center;
    background-color:white;
    width: 100%;
    border-radius: 10px;
    border: 2px solid #eda808;
    color:#eda808;
  }


  .textarea {
    position: relative;
    font-family: var(--font-family);
    font-size: 16px;
    font-weight: 500;
    width: 100%;
    min-height: 220px;
    box-sizing: border-box;
    padding: 16px;
    margin-bottom: 0px;
    margin-top: 0px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-family: var(--font-family);
  }
  
  .controls {
    display: flex;
    align-items: center;
  }
  
  .dropdown {
    padding: 8px;
    margin-right: 16px;
  }
  
  .checkbox {
    margin-right: 8px;
  }

  /* Slider.css */
/* Slider.module.css */

.sliderContainer {
    width: 10%;
    margin: 20px auto;
    background-color: red;
    text-align: center;
  }
  
  .sliderInput {
    width: 90%;
    margin: 10px 0;
    background-color: red;
    --track-color: var(--brand-color); /* Custom color variable */
  }
  
  .sliderInput::-webkit-slider-thumb {
    background-color: var(--brand-color); /* Custom color variable */
  }
  
  .sliderLabels {
    display: flex;
    justify-content: space-between;
    color: var(--text-color); /* Custom color variable */
  }
  
  .sliderValue {
    margin-top: 10px;
    color: var(--text-color); /* Custom color variable */
  }

  

  .advancedSettings{
    position: absolute;
    overflow: hidden;
    bottom:0px;
    left: 0px;
    right: 0px;
    background-color: white;
    border-radius: 24px 24px 0px 0px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    animation-name: slideUp;
    animation-timing-function: cubic-bezier(.33,0,.1,1);
    animation-duration: 400ms;
    animation-fill-mode: forwards;
  }
  @keyframes slideUp {
    0%{
        transform: translateY(250px);
    }
    100%{
        transform: translateY(0px);
    }
  }

  .closeBtn{
    display: grid;
    place-content: center;
    border-radius: 100px;
    position: absolute;
    right: 16px;
    top: 16px;
    height: 20px;
    width: 20px;
    color:white;
    background-color: var(--brand-color)
  }

  .doubleArrows{
    width: fit-content;
    display: grid;
    place-content: center;
    grid-template-columns: 28px 1fr;
    position: relative;
    margin-right: auto;
    margin-left: 18px;
    margin-top: 16px;
    color:rgba(0, 0, 0, 0.75);
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 18px;
  }

  .settingsWrapper{
    padding: 16px;
  }
  span{
    font-family: var(--font-family);
    font-size: 14px;
    font-weight: 700;

  }

