/* ThankYouPage.css */
.thank-you-page {
    text-align: center;
    display: grid;
    place-content: center;
    margin-top:-40px;
    height:100vh;
    width:100vw;
    padding: 20px;
    font-family: 'DM Sans', sans-serif;
  }
  
  .logo {
    max-width: 300px;
    margin:auto;
    height: auto;
    margin-bottom: 20px;
  }
  
  .heading {
    font-size: 38px;
    font-weight: 900;
    font-weight: bold;
    padding-left:10%;
    padding-right:10%;
    margin-bottom: 10px;
  }
  
  .description {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 20px;
    padding-left:10%;
    padding-right:10%;
  }
  