.ad-campaign {
    font-family: DM Sans;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }

  .pa-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .pa-table th,
  .pa-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  /* Additional styles for specific columns */
  .pa-table th:nth-child(1),
  .pa-table td:nth-child(1) {
    /* Styles for the first column */
  }
  
  .pa-table th:nth-child(2),
  .pa-table td:nth-child(2) {
    /* Styles for the second column */
  }