/* GLOBAL STYLES */
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Charis+SIL:wght@400;700&family=DM+Sans:wght@400;500;700&family=Inter+Tight:wght@100;200;300;400;500;600;700;800;900&family=Lora:wght@400;500;600;700&display=swap');


:root {
  --primary-color: #5c46b3;
  --secondary-color: #FFFFFF;
  --secondary-text: #14092f;
  --text-color: #4a4a4a;
}

body {
  scroll-behavior: smooth;
  margin: 0;
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 16px;
  line-height: 1.6;
  color: var(--text-color);
}cabacacacaca

a {
  color: var(--primary-color);
  text-decoration: none;
  transition: color 0.3s;
}

a:hover {
  color: var(--primary-color);
}

.nav-spacer{
  padding-top: 8px;;
  font-family: DM Sans;
  font-weight: 700;
  font-size: 16px;
  padding-left: 8px;
  padding-right: 8px;
  cursor: pointer;
}

.step-number{
  position: absolute;
  opacity: .5;
  top:40px;
  left:58%;
  margin:auto;
  margin-bottom: 12px;
  padding: 8px;
  display: grid;
  border-radius: 100px;
  background-color: var(--primary-color);
  width: 20px;
  height: 20px;
  font-style: DM Sans;
  font-weight: 500;
  font-size:24px;
  color:white;
  place-content: center;
}

.container {
  position: relative;
  display: block;
  max-width: 1400px;
  overflow: hidden;
  margin: 0 auto;
  padding: 0 0px;
}

/* HEADER STYLES */

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid #eee;
  background-color: white;
}

.menu-toggle {
  display: inline-block;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0px;
  /* margin-right: -10px; */
}

.hamburger {
  display: block;
  width: 25px;
  height: 3px;
  background: var(--text-color);
  border-radius: 3px;
  position: relative;
}

.hamburger::before, .hamburger::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background: var(--primary-color);
  border-radius: 3px;
  position: absolute;
  left: 0;
  transition: transform 0.3s;
}

.hamburger::before {
  transform: translateY(-8px);
}

.hamburger::after {
  transform: translateY(8px);
}


nav {
  display: flex;
  justify-content: flex-end; /* Right-align on desktop */
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.vert-menu{
  position: absolute;
  display: none;
  right:10px;
  top: 74px;
  width:50%;
  max-width: 280px;
  background-color: white;
  box-shadow:  0 10px 16px rgba(25, 25, 25, 0.18);
  padding: 20px;
  z-index: 10;
  border-radius: 10px;
  animation: openMenu .33s cubic-bezier(0,0,0,1);
}

@keyframes openMenu {
  0%{
    opacity: 0;
    transform: translate(0px, -20px);
  }
  100%{
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

.vert-menu-spacer{
  position: relative;
  margin-bottom: 20px;
  cursor: pointer;
}

/* Style for desktop screens */
@media only screen and (min-width: 768px) {
  .nav-spacer {
    margin-right: 20px;
  }
}





nav a:hover {
  border-bottom: 2px solid var(--primary-color);
}

/* Hero section styles */
.hero {
  display: flex;
  /* grid-template-columns: 1fr 1fr;
  grid-template-areas: "mainImg mainText"; */
  width:100%;
  padding: 0px 0;
  height: 500px;
  background-color: var(--secondary-color);

}

.hero-text {
  max-width: 650px;
  grid-area: mainText;
  text-align: left;
  width: 100%;
  margin: 0 auto;
  margin-top:20px;
  padding-left: 50px;

}

#masthead-img{
  grid-area: mainImg;
  display: block;
  height: 100%;
  transform: translate(20px, 0px);
}

.hero-heading {
  font-family: Lora;
  line-height: 60px;
  font-size: 54px;
  font-weight: 600;
  color: rgb(17, 17, 28);
  margin-bottom: 20px;
}

.RequestDemo{
  display: flex;
  background-color: transparent;
  border: 2px solid var(--primary-color);
  color: var(--primary-color);
  font-family: DM Sans;
  font-weight: 700;
  cursor: pointer;
  margin-right: 16px;
  border-radius: 100px;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 16px;
  padding-right: 16px;
  width:fit-content;
}


.CreateAdNav{
  display: flex;
  background-color: transparent;
  background-color: var(--primary-color);
  font-family: DM Sans;
  color:white;
  font-weight: 700;
  cursor: pointer;
  border-radius: 100px;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 16px;
  padding-right: 16px;
  width:fit-content;
}
.hero-subheading {
  font-size: 20px;
  font-weight: 400;
  color: var(--secondary-text);
  margin-bottom: 30px;
}
.faq-mast{

}

.hero-button {
  padding: 15px 20px;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  font-weight: bold;
  background-color: var(--primary-color);
  border-radius: 25px;
  border: none;
  cursor: pointer;
  width: fit-content;
  transition: all 0.3s ease;
  font-family: DM Sans;
  box-shadow:  0 10px 16px rgba(25, 25, 25, 0.18);
  font-weight: 700;
}

.hero-button:hover {
  background-color: #745bda
}

.CreateAdNav:hover {
  background-color: #745bda
}

/* HOW IT WORKS SECTION */

.how-it-works {
  padding: 10px 0;
  text-align: center;
}

.section-header {
  margin-bottom: 50px;
}

.segmenation{
  padding:36px;
}

.benefits {
  
  background-color: #FDFCFC;
  padding: 30px 0;
}

.section-header {
  text-align: center;
  margin-bottom: 60px;
}

.section-heading {
  font-size: 48px;
  font-family: DM Sans;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
}

.section-subheading {
  padding-left: 30px;
  padding-right: 30px;
  margin:auto;
  width:80%;
  font-size: 24px;
  font-family: DM Sans;
  color: #666;
}
strong{
  text-decoration: underline;
  color: var(--primary-color)
}

.howitworks-icons{
  
  transform: scale(1.2);
}

.benefits-container {
  display: grid;
  padding-left: 30px;
  padding-right: 30px;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap:20px;
  margin: 0 auto;
}



.couchimg{
  display: block;
  height: 700px;
  transform: translate(-2%, 0%);
}

.benefit {
  font-family: DM Sans;
  text-align: center;
  padding: 30px;
  background-color: #FDFCFC;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.benefit img {
  margin-bottom: 20px;
}

.benefit h3 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
}

.targeting-main{
  display: grid;
  grid-template-columns: 1fr 600px;
  text-align: left;
  padding: 28px;
}

.benefit p {
  font-size: 18px;
  color: #666;
  line-height: 1.5;
}
.brand-clip{
  width: 100%;
  max-width: 1400px;
  overflow: hidden;
}
.brandTop{
  height: 70px;
  animation: slideTop 65s linear infinite;
}
.brandBottom{
  height: 70px;
  float: right;
  margin-bottom: 40px;
  animation: slideBottom 65s linear infinite;
  
}

@keyframes slideTop {
  0%{
    transform: translate(0%);
  }
  50%{
    transform: translate(-54%);
  }
  100%{
    transform: translate(0%);
  }
}

@keyframes slideBottom {
  0%{
    transform: translate(0%);
  }
  50%{
    transform: translate(54%);
  }
  100%{
    transform: translate(0%);
  }
}


.steps {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-auto-rows: 340px;
  gap:10px;
  align-items: top;
  margin-top: 0px;
}

.step {
  text-align: center;
  position: relative;
  height: auto;
  padding: 40px;

}

.step img {
  margin-bottom: 20px;
  height: 120px;
  width: 120px;
  max-width: 100%;
  object-fit: contain;
}

.step h3 {
  font-family: DM Sans;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #333;
}

.step p {
  font-size: 16px;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 1.5;
  color: #666;
  margin-bottom: 0;
}





/* move hamburger to right side of header */
.hamburger {
  float: right;
  margin-right: 0px;
}
.networks{
  margin-bottom: 50px;
}
.networks-img{
  position: relative;
  display: block;
  margin:auto;
  width:80%;
}
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.brand {
  width: 230px;
  font-size: 24px;
  font-family: DM Sans;
  font-weight: 700;
  padding-left: 30px;
  color:var(--primary-color)
}

.menu-toggle {
  display: none; /* hide the hamburger button by default */
}

nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

ul {
  list-style: none;
  margin: 0;

  padding: 0;
  display: flex;
}

li {
  margin: 0 10px;
}

a {
  text-decoration: none;
  font-family: DM Sans;
  font-weight: 500;
  color:var(--primary-color)
}




/* 1200-1400 */
@media (min-width: 1200px) and (max-width: 1400px) {
  .hero{
    height: 500px;
    width: 100vw;
    overflow: hidden
  }
  
  
  .hero-text{

    min-width: 40%;
  }
  .hero-heading{
    font-size: 56px;
    line-height: 58px;
  }
  .hero-subheading{
    font-size: 18px;
  }
  .steps {
    margin-top: 0px;
  }
}

/* 1200-1000 */
@media (min-width: 700px) and (max-width: 1200px) {
  
  .hero{
    height: 500px;
    width: 100vw;
    overflow: hidden;
  }
  .hero-text{
    
    min-width: 40%;

  }
  .couchimg{
    display: none;
    height: 600px;
  }
  .targeting-main{
    display: block;
    text-align: center;
  }
  
  .hero-heading{
    font-size: 52px;
    line-height: 54px;
  }
  .hero-subheading{
    font-size: 16px;
    margin-bottom: 20px
  }
  .hero-button{
    font-size: 14px;
  }
  .steps {
    margin-top: 0px;
  }
  .section-heading{
    font-size: 34px;
  }
  .section-subheading{
    font-size: 22px;
  }
 
}

@media (max-width: 950px) {
  nav {
    display: none; /* show the horizontal menu */
  }
  .menu-toggle{
    display: block;
  }
  .vert-menu{
    display: block;
  }
}



@media (max-width: 1100px) {
  .hero-heading{
    font-size: 48px;
    line-height: 52px;
  }
}

@media (max-width: 1000px) {
  .hero-heading{
    font-size: 44px;
    line-height: 48px;
  }
  .hero-subheading{
    font-size: 16px;
    margin-bottom: 20px
  }
  
}

@media (max-width: 850px) {
  .hero-heading{
    font-size: 36px;
    line-height: 44px;
  }
  .hero-subheading{
    font-size: 14px;
    margin-bottom: 20px
  }
  
}

/* 1000-800 Make Stacked */
@media (min-width: 0px) and (max-width: 700px) {
  #masthead-img{
    display: none;
  }
  .couchimg{
    display: none;
    height: 600px;

  }

  .hero{
    display: block;
    height: auto;

  }
  .hero-text{
    
    max-width: 100vw;
    padding-left: 0px;
    margin:auto;
    
  }
  .targeting-main{
    display: block;
    text-align: center;
  }
  .brandTop{
    height: 50px;
  }
  .brandBottom{
    height: 50px;
  }
  .hero-heading{
    margin: auto;
    width:80%;
    margin-top:50px;
    font-size: 36px;
    line-height: 44px;
    margin-bottom: 20px;
    text-align: center;
  }
  .hero-subheading{
    font-size: 15px;
    width: 70%;
    margin:auto;
    margin-bottom: 20px;
    text-align: center;
  }
  .hero-button{
    display: block;
    margin: auto;
    margin-bottom: 50px;
    font-size: 14px;
    padding: 14px;
  }
  .steps {
    margin-top: 0px;
  }
  
  .howitworks-icons{
    margin-top: 20px;
    transform: scale(1.3);
  }
  .step-number{
    top:50px;
  }
  .steps{
    gap: 30px;
  }

  
  .menu-toggle {
    display: block; /* show the hamburger button */
    margin-right: 0px;
  }
  .brand{
    width: 200px;
    padding-left: 10px;
  }
  .section-heading{
    font-size: 26px;
  }
  .section-subheading{
    font-size: 17px;
  }
  .benefits-container{
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
}

