/* Global Styles for CampaignDetails */
#campaign-details-container {
    background-color: white;
    font-family: DM Sans;
    margin: 0;
    padding: 0;
    color: #333;
  }
  
  #campaign-details-content {
    position: relative;
    text-align: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px;
    margin-top: 20px;
    background-color: #fff;
    border: 2px solid lightgrey;
    border-radius: 18px;
  }
  
  #campaign-details-title {
    font-size: 32px;
    font-weight: 600;
    text-align: center;
    color: #111;
    margin-bottom: 20px;
  }
  
  #campaign-details-description {
    font-size: 18px;
    font-weight: 300;
    line-height: 1.6;
    color: #666;
    text-align: center;
    margin-bottom: 40px;
  }
  
  .campaign-details-section {
    margin-bottom: 40px;
  }
  
  .campaign-details-section-title {
    font-size: 24px;
    font-weight: 500;
    color: #111;
    margin-bottom: 20px;
  }
  
  .campaign-details-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  
  .campaign-details-label {
    font-weight: 500;
    color: #333;
  }
  
  .campaign-details-value {
    font-weight: 400;
    color: #666;
  }
  
  .campaign-details-container {
    background-color: #fafafa;
    padding: 20px;
    border: 2px solid lightgrey;
    border-radius: 10px;
    transition: box-shadow 0.3s ease;
  }
  
  .campaign-details-container:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .campaign-details-header {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    border-bottom: 1px solid #eaeaea;
    padding-bottom: 10px;
  }
  
  .campaign-details-title {
    font-size: 20px;
    font-weight: 500;
    color: #333;
  }
  
  .campaign-details-toggle-arrow {
    font-size: 16px;
    color: #666;
  }
  
  .campaign-details-info {
    padding-top: 15px;
  }
  
  .campaign-details-subsection {
    margin-bottom: 20px;
  }
  
  ul.campaign-details-list {
    list-style-type: none;
    padding: 0;

  }
  
  ul.campaign-details-list li {
    margin-bottom: 8px;
    color: #666;
  }
  
  a.campaign-details-link {
    color: #007aff;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  a.campaign-details-link:hover {
    color: #005bb5;
  }
  
  /* Creative Section */
  .campaign-details-creative-item {
    margin-top: 20px;
  }
  
  .campaign-details-creative-image {
    max-width: 100%;
    border-radius: 6px;
    margin-top: 10px;
  }
  
  .campaign-details-creative-video,
  .campaign-details-creative-audio {
    width: 100%;
    margin-top: 10px;
    border-radius: 6px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Password Protection Section */
  #campaign-details-password-container {
    display: flex;
    flex-direction: column;
    gap:16px;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f9f9f9;
  }
  
  #campaign-details-password-container h2 {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 20px;
    text-align: center;
    color: #333;
  }
  
  #campaign-details-password-container form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  #campaign-details-password-container input[type="password"] {
    width: 300px;
    padding: 10px 15px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 6px;
    margin-bottom: 15px;
    outline: none;
    transition: border-color 0.3s ease;
  }
  
  #campaign-details-password-container input[type="password"]:focus {
    border-color: var(--brand-color)
  }
  
  #campaign-details-password-container button {
    padding: 10px 20px;
    background-color: #007aff;
    color: #fff;
    font-size: 16px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .audience{
    border: 1px solid lightgrey;
    border-radius: 10px;
    margin-bottom: 16px;
    padding: 10px;
  }
  
  #campaign-details-password-container button:hover {
    background-color: #005bb5;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    #campaign-details-content {
      padding: 20px;
    }
  
    #campaign-details-title {
      font-size: 28px;
    }
  
    #campaign-details-description {
      font-size: 16px;
    }
  
    .campaign-details-section-title {
      font-size: 20px;
    }
  
    .campaign-details-title {
      font-size: 18px;
    }
  
    #campaign-details-password-container input[type="password"] {
      width: 100%;
    }
  }
  