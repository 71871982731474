.invoice-page {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    color: #333;

}

.invoice-container {
    background-color: white;
    border-radius: 12px;
    padding: 30px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.invoice-header {
    text-align: center;
    margin-bottom: 20px;
}

.invoice-logo {
    width: 150px;
    margin-bottom: 10px;
}

.invoice-date {
    color: #555;
    font-size: 14px;
}

.invoice-description, .invoice-contact, .invoice-summary {
    margin-bottom: 20px;
    padding: 15px;
    background-color: #f9f9f9;
    border-radius: 8px;
}

.invoice-campaigns {
    margin-bottom: 20px;
}

.invoice-table {
    width: 100%;
    border-collapse: collapse;
}

.invoice-table th,
.invoice-table td {
    padding: 12px;
    border-bottom: 1px solid #ddd;
    text-align: left;
}

.invoice-table th {
    background-color: #f0f0f0;
}

.invoice-summary p {
    margin: 8px 0;
}

.invoice-footer {
    text-align: center;
    padding-top: 10px;
    font-size: 14px;
    color: #888;
    border-top: 1px solid #ddd;
}

.invoice-loading {
    text-align: center;
    font-size: 18px;
    color: #555;
    padding: 50px;
}

.invoice-error {
    text-align: center;
    font-size: 18px;
    color: #e74c3c;
    padding: 50px;
}

.invoice-download-button {
    display: block;
    margin: 20px auto;
    padding: 12px 24px;
    background-color: #007aff;
    color: white;
    font-size: 16px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.invoice-download-button:hover {
    background-color: #005bb5;
}
