#create-container{
    width: 100wh;
    padding:30px;
    font-family: DM Sans;
    font-size: 16px;

}
#create-container-new{
  width: 100wh;
  padding:18px;
  font-family: DM Sans;
  font-size: 16px;
  background-color: white;
  /* background-image: url(./bg2.png); */
}

#master-grid{
  display: grid;
  grid-template-columns: 1fr 2.5fr 1fr;
}

.error-msg{
  color:red;
  font-size: 16px;
}

.audio-video-toggle{
  position: relative;
  margin: auto;
  margin-bottom: 24px;
  height:34px;
  width: fit-content;
  padding-left: 18px;
  padding-right:18px;
  background-color: white;
  border: 2px solid rgb(216, 216, 216);
  border-radius: 30px;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  gap:32px;
}

#newCampginBtn{
  background-color: #004432;
}

#newCampginBtn:hover{
  background-color: var(--primary-color);
}

#newCampginBtn:before{
  content: "+";
  display: inline-block;
  margin-right: 8px; 
  font-size: 16px; 
  font-weight: bold; 
}

textarea{
  border-radius: 8px;
  padding:8px;
  width: 96%;
  height: 100px;
  border: none;
  outline: 2px solid #e5e5e5;
  font-family: DM Sans;
}
textarea:focus{
  outline: 2px solid var(--primary-color)
}
 
.toggleAudio{
  padding-top: 3px;
  cursor: pointer;
  font-weight: 600;
}
.toggleVideo{
  padding-top: 3px;
  cursor: pointer;
  font-weight: 600;
}


.slider-focus{
  position: absolute;
  left:0px;
  top: 0px;
  border-radius: 30px;;
  height:34px;
  z-index: 0;
  background-color: var(--primary-color);
  width:  110px;
  transition: all .3s cubic-bezier(.33,0,0,1)
}

.section-wrapper{
    display: block;
    position: relative;
    margin:auto;
    width: 100%;
    max-width:500px;
    margin-bottom: 16px;
    overflow: hidden;
    padding: 0px;
    opacity: 0;
    border: 1px solid rgb(197, 197, 197);
    border-radius: 20px;
    transition: max-height 0.4s cubic-bezier(0,0,0,1);
    animation: slideUp .5s cubic-bezier(.33,0,0,1) forwards;
}

@keyframes slideUp {
    0%{
      opacity: 0;
      transform: translate(0px, 100px);
    }
    100%{
      opacity: 1;
      transform: translate(0px, 0px);
    }
}

.section-wrapper:nth-child(3) { animation-delay: 0s }
.section-wrapper:nth-child(4) { animation-delay: .15s }
.section-wrapper:nth-child(5) { animation-delay: .3s }
.section-wrapper:nth-child(6) { animation-delay: .45s }
.section-wrapper:nth-child(7) { animation-delay: .6s }
.chevron-container{
    position: absolute;
    right: 20px;
    justify-self: flex-end;
    display: flex;
    gap: 6px;
    place-content: center;
  }
  



  .chevron-img{
    width:26px;
    transition: all .25s ease-in-out;
    transform: scale(1);
    animation: bounceIn 1s linear forwards;
  }
  
  @keyframes bounceIn {
    0%{
      transform: scale(.5);
      animation-timing-function: cubic-bezier(.33,0,0,1);
    }
    25%{
      transform: scale(1.15);
      animation-timing-function: cubic-bezier(.33,0,0,1);
    }
    50%{
      transform: scale(.9);
      animation-timing-function: cubic-bezier(.33,0,.67,1);
    }
    75%{
      transform: scale(1.05);
      animation-timing-function: cubic-bezier(.33,0,.67,1);
    }
    100%{
    transform: scale(1);
    }
  }


.create-content-wrapper{
  position: relative;
    padding:20px
}

.content-grid{
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap:4px;
    padding:20px
}

#create_logo{
    display: block;
    width: 300px;
    transform: translate(-16px, 0px);
    margin:auto;
}

.main-upload {
    display: grid;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 500px;
    height:250px;
    border: 2px dashed #666;
    border-radius: 4px;
    font-size: 16px;
    margin:auto;
    color: #888;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
  
  .main-upload:hover {
    border-color: rgb(42, 158, 104);
  }
  
  .main-upload p {
    margin: 0;
  }
  
  .main-upload input {
    display: none;
  }

.right-btns{
    display: flex;
    width:100%;
    gap:10px;
    justify-content: flex-end
}

.next-btn{
    background-color: var(--primary-color);
    color:white;
    outline: none;
    font-family: DM Sans;
    font-weight: 700;
    font-size: 14px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 100px;
}

.back-btn{
    background-color: transparent;
    color:var(--primary-color);
    outline: none;
    font-family: DM Sans;
    font-weight: 700;
    font-size: 14px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 100px;
    border: 2px solid var(--primary-color);
}



.progress-step-num{   
    display: grid;
    place-content: center;
    color: var(--primary-color);
    border: 2px solid var(--primary-color);
    border-radius: 100px;
    width: 26px;
    height:26px;
    font-size: 16px;
    font-weight: 700;
  
}

.step-header{
    display: flex;
    padding-left: 14px;
    align-items: center;
    gap: 12px;
}

.input-wrapper{
  display: block;
  cursor: pointer;
  position: relative;
  margin: auto;
}

.gtg{
  text-align: center;
  font-family: DM Sans;
  font-weight:500;
  margin-bottom:0px;
  color: rgb(42, 185, 159);
  font-size: 20px;
}
.error-banner{
  position: fixed;
  display: grid;
  place-content: center;
  left:0px;
  font-weight: 700;
  bottom: 0px;
  width: 100vw;
  height: 40px;
  background-color: rgb(255, 140, 0);
  color:white;
  animation: errorSlide .45s cubic-bezier(.33,0,.1,1)
  
}

@keyframes errorSlide {
    0%{
      transform: translate(0%, 100%)
    }
    100%{
      transform: translate(0%, 0%)
    }
}

#proceed-to-payment{
  display: block;
  margin:auto;
  border-radius: 100px;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 8px;
  padding-bottom:8px;
  outline:none;
  background-color: var(--primary-color);
  color:white;
  font-family: DM Sans;
  font-weight: 800;
  font-size:14px;
}

.selected-local-pill{
  width:fit-content;
  margin-bottom: 8px;
  height:30px;
  border-radius: 100px;
  color:white;
  font-weight: 600;
  padding:6px;
  padding-left: 12px;
  padding-right: 12px;
  background-color: var(--primary-color);
}

.x-btn{ 
  background-color: transparent;
  outline: none;
  box-shadow: none;
  font-size: 14px;;
  border: none;
  color:white;
  margin-left: 6px;
  font-weight: 700;
}


input[type="text"],
input[type="email"],
input[type="date"],
input[type="url"],
input[type="number"],
input[type="tel"],
input[type="password"] {
  display: block;
  position: relative;
  font-size: 16px;
  padding: 12px 16px;
  border: 2px solid rgb(229, 229, 229);
  border-radius: 8px;
  background-color: #ffffff;
  color: #333;
  box-shadow: none;
  transition: border-color 0.3s ease-in-out;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus {
  border-color: var(--primary-color);
  outline: none;
}

input[type="number"]::before{
  content:'$'
}





label {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 4px;
}


@media (min-width: 0px) and (max-width: 700px) {
  #create_logo{
    width: 200px;
  }
  .content-grid{
    display: block;
  }
}