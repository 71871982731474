.upload-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 200px;
    width: 400px;
    border: 2px dashed #ccc;
    font-family: "DM Sans", sans-serif;
  }
  
  .upload-box h2 {
    font-size: 24px;
    font-weight: bold;
    color: #333;
  }
  .upload-parse-container{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    place-content: center;
}

  .upload-parse-success{
    display: block;
    text-align: center;
    place-content: center;
  }
.fileSpec-attr{
    font-weight: 700;
    font-size: 14px;
    color:var(--primary-color)
  }
  .lottie-parse-check{
    margin-bottom: -14px;
    display: grid;
    margin-right: 14px;
    place-content: center;
  }

  .baseBtn{
    background-color:var(--primary-color);
    z-index: 100;
    position: absolute;
    display: grid;
    cursor: pointer;
    place-content: center;
    right: 10px;
    top: 10px;
    width: auto;
    font-size: 18px;
    padding: 8px 8px 8px 8px;

    border-radius: 100px;
    color: white;

  }
  .closeXbtn{
    background-color:var(--primary-color);
    z-index: 100;
    /* position: absolute; */
    display: grid;
    cursor: pointer;
    place-content: center;
    left: 10px;
    top: 10px;
    width: 22px;
    height:22px;
    font-size: 20px;
    padding: 0;
    border-radius: 100px;
    color: white;
  }

  #progressSheen{
    position: absolute;
    top:0px;
    left: 0px;
    height:4px;
    width:100%;
    transform: translate(0%, 0%);
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgb(238, 238, 238), rgba(255, 255, 255, 0));
    animation: sheenRight 1.4s cubic-bezier(.33,0,0,1) infinite;
  }
  
  @keyframes sheenRight {
    0% {
      transform: translate(-100%, 0%);
    }
    100% {
      transform: translate(100%, 0%);
    }
  }

  .logo-upload-wrapper {
    position: relative;
    display: block;
  }
  
  .logo-upload-wrapper label {
    display: block;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;

  }

  .additional-assets{
    border:1px solid lightgrey;
    border-radius: 8px;
    padding:16px;
  }
  
  .logo-upload-wrapper input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    height: 0;
    width: 0;
  }
  
  .logo-upload-wrapper img {
    max-width: 100%;
    margin-top: 10px;
  }

  .tooltip {
    display: inline-block;
    font-family: DM Sans;
    font-size:14px;
    padding: 1px;
    margin-left: 5px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 1px solid #ccc;
    z-index: 100;
    text-align: center;
    line-height: 12px;
    cursor: pointer;
  }
  
  .tooltip:hover::after {
    content: attr(title);
    display: block;
    position: absolute;
    top: -25px;
    left: 0px;
    font-weight: 300;
    border: 1px solid #ccc;
    background-color: rgb(255, 255, 255);
    color: #fff;
    color: #121212;
    padding: 5px;
    border-radius: 5px;
    text-align: left;
    font-size: 12px;
    width: 150px;
  }
  
  .logo-upload-wrapper .error {
    color: red;
    font-size: 14px;
    margin-top: 10px;
  }
  
  /* Style the label to look like a button */
  .logo-upload-wrapper label {
    display: inline-block;
    font-family: DM Sans;
    font-size: 14px;
    padding: 10px 20px;
    background-color: var(--primary-color);
    color: white;
    border-radius: 5px;
    cursor: pointer;
  }
  
  /* Style the label to change color when hovered */
  .logo-upload-wrapper label:hover {
    filter: brightness(1.1);
  }
  
  /* Style the label to change color when pressed */
  .logo-upload-wrapper label:active {
    filter: brightness(1.2);
  }

  .upload-box p {
    font-size: 16px;
    color: #666;
    margin-top: 16px;
  }
  
  .upload-box input[type="file"] {
    display: none;
  }
  
  .upload-box label {
    font-size: 16px;
    font-weight: bold;
    color: #666;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .upload-box label:hover {
    color: #333;
  }
  
  .upload-box .error {
    color: #ff0000;
    font-size: 14px;
    margin-top: 8px;
  }


 .error {
    color: #ff0000;
    font-size: 14px;
    margin-top: 8px;
  }
  