/* Container for the fixed bottom banner */
.checkout-banner {
    position: relative;
    display: 'block';
    width: 560px;
    margin: auto;
    border-radius: 20px;
    overflow: hidden;
    background-color: white;
    border-top: 1px solid #ddd;
    height: auto;
    border: 1px solid lightgrey;
    margin-bottom: 60px;
    transition: transform 0.3s ease-in-out;
}

/* Banner summary when closed */


/* Line items in the expanded section */
.line-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.completedSection{
    color:#088D32;
    padding: 0px;
    margin:0px;
}
.missingInputs{
    color:#F90E2D;
    padding: 0px;
    margin:0px;
}
.line-item h4 {
    margin: 0;
    font-size: 1rem;
    color: #555;
}

.line-item p {
    margin: 0;
    font-size: 0.9rem;
    color: #000;
}

/* Checkout button */
.checkout-button {
    background-color: var(--brand-color);
    color: white;
    border: none;
    padding: 12px;
    font-size: 1.1rem;
    font-weight: 600;
    border-radius: 60px;
    cursor: pointer;
    width: 100%;
    text-align: center;
}

.checkout-button:hover{
    filter:brightness(1.3);
    background-color: #004432;
}

.checkout-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

#cartIcon{
    display: flex;
    place-content: center;
    align-items: center;
    width: 100%px;
    height: 36px;
    background-color: var(--brand-color);
}
